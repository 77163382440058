<template>
  <div class="" style="width: 100%;">
    <v-container>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>New {{this.singular}}</h1>
        </v-col>
        <v-col>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
              label="First Name"
              outlined
              v-model="data.firstName"
          />
          <v-text-field
              label="Last Name"
              outlined
              v-model="data.lastName"
          />
          <v-text-field
              label="Address"
              outlined
              v-model="data.address"
          />
          <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="data.dateOfBirth"
                  label="Date of Birth"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
              />
            </template>
            <v-date-picker
                v-model="data.dateOfBirth"
            />
          </v-menu>
          <v-select
              item-text="name"
              item-value="id"
              :items="userTypes"
              label="User Type"
              outlined
              v-model="data.userTypeId"
          />
        </v-col>
        <v-col>
          <v-text-field
              label="Phone"
              outlined
              v-model="data.phone"
          />
          <v-text-field
              label="Email"
              outlined
              v-model="data.email"
          />
          <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="data.hireDate"
                  label="Hire Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
              />
            </template>
            <v-date-picker
                v-model="data.hireDate"
            />
          </v-menu>
          <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="data.fireDate"
                  label="Fire Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
              />
            </template>
            <v-date-picker
                v-model="data.fireDate"
            />
          </v-menu>
          <v-text-field
              label="Password"
              outlined
              v-model="data.password"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="create" :disabled="!this.data.firstName || !this.data.password">Create</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        loader: false,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        data: {},
        userTypes: [],
        singular: "User",
        singularLower: "user",
        plural: "Users",
        pluralLower: "users"
      }
    },
    async mounted(){
      try{
        let uts = await axios.get(`${this.getEndpoint}/api/usertypes`);
        if(uts.data.error) throw uts.data.error

        this.userTypes = uts.data.data;
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loader = false;
      }
    },
    computed: {
      ...mapGetters(['getEndpoint'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async create(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.data)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} Created 🎉`, "success");

          await this.$router.push({ path: `/${this.pluralLower}/view/${res.data.data.id}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
    }
  }
</script>
